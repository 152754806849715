@import '../../mixins.scss';

.password-container {
  background-color: white;
  width: 100%;
  font-family: $body-font;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__subtitle {
    font-family: $body-font;
    font-weight: 400;
    font-size: 18px;
    max-width: 600px;
    text-align: center;
  }
  &__title {
    text-align: center;
    font-family: $heading-font;
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 0;
  }
  form {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    align-items: center;
    .input-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      label {
        color: #555;
        font-family: $body-font;
        font-size: 18px;
        padding-right: 10px;
      }
      input {
        padding: 8px 20px 8px 20px;
        border: 2px solid #aaa;
        border-radius: 5px;
        font-family: $body-font;
        font-size: 18px;
      }
    }
  }
  &__message {
    // text-align: center;
  }
  &__submit {
    margin-top: 20px;
    font-family: $body-font;
    width: max-content;
    padding: 6px 30px 8px 30px;
    color: white;
    background-color: $blue;
    border-radius: 5px;
    font-weight: 700;
    font-size: 24px;
    border: none;
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: $orange;
    }
    &.disabled {
      background-color: #aaa;
    }

  }
 
}


