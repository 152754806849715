@import '../../mixins.scss';

.footer-area {
  @include container-padding;
  background-color: #111;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;

  &__title {
      font-family: $heading-font;
      font-weight: 700;
      font-size: 20px;
  }

  &__content {
      @include container-width;
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
  }

  &__footer-address {
      display: flex;
      flex-direction: column;
      & p {
          font-family: $body-font;
          font-weight: 400;
          font-size: 14px;
          margin-top: 0;
          margin-bottom: 5px;
          & a, a:visited {
              text-decoration: none;
              color: $highlight;
          }
      }
  }

  &__phone {
      font-family: $heading-font;
      font-weight: 400;
      font-size: 20px;

      & span {
          font-weight: 700;
          background: -moz-linear-gradient(0deg, #00b994 0%, #0077e6 100%);
          background: -webkit-linear-gradient(0deg, #00b994 0%, #0077e6 100%);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
      }
  }
  &__payment-logos {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      & img {
          height: 40px;
          padding-right: 20px;
      }
  }

  &__links {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: $break-wide) {
      flex-direction: row;
    }
    justify-content: space-between;
    font-size: 12px;
    a {

      color: white;
      text-decoration: none;
      &:visited {
        color: white;
      }
      &.admin-link {
        color: #555;
      }
      &:hover {
        color: orange;
      }
    }
  }
  &__page-links {
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: $break-wide) {
      flex-direction: row;
    }
    justify-content: flex-start;
    a {
      padding-right: 20px;
    }
  }
  &__mail-links {
    display: flex;
    padding-top: 10px;
    flex-direction: column;
    @media only screen and (min-width: $break-wide) {
      padding-top: 0;
      flex-direction: row;
    }
    justify-content: flex-start;
    a {

      padding-left: 0;
      &.admin-link {
        order: 2;
      }
      @media only screen and (min-width: $break-wide) {
        padding-left: 20px;
        &.admin-link {
          order: 0;
        }
      }
    }
  }
}
