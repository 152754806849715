@import '../../mixins.scss';

.notfound-container {
  width: 100%;
  background-color: white;
  font-family: $body-font;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  &__subtitle {
    font-weight: 400;
    text-align: center;
    font-size: 18px;
  }
  &__title {
    font-family: $heading-font;
    font-weight: 700;
    text-align: center;
    font-size: 36px;
    margin-bottom: 0;
  }
  &__home {
    font-weight: 400;
    text-align: center;
    font-size: 16px;
    a, a:visited {
      text-decoration: none;
      color: $blue;
      &:hover {
        color: $orange;
      }

    }
  }
}
