@import '../../mixins.scss';

.service-container {
  width: 100%;
  background-color: white;
  font-family: $body-font;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  &__subtitle {
    font-weight: 400;
    text-align: center;
    font-size: 18px;
  }
  &__title {
    font-family: $heading-font;
    font-weight: 700;
    text-align: center;
    font-size: 36px;
    margin-bottom: 0;
  }
}
.services {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  &__tile {
    background-image: -moz-linear-gradient(45deg, #00b994 0%, #0077e6 100%);
    background-image: -webkit-linear-gradient(45deg, #00b994 0%, #0077e6 100%);
    width: 100%;
    margin-bottom: 20px;
    @media only screen and (min-width: 600px) {
      width: 48.5%;
    }
    @media only screen and (min-width: 1000px) {
      width: 24%;
    }
    flex-grow: 0;
    font-family: $body-font;
    color: white;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;

    h2 {
      font-family: $heading-font;
      font-weight: 700;
      text-align: center;
      font-size: 20px;
      @media only screen and (min-width: 600px) {
        font-size: 16px;
      }
      @media only screen and (min-width: 1250px) {
        font-size: 18px;
      }
    }
    .services__image {
      position: relative;
      img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 1s;
        &:first-child {
          position: relative;
          opacity: 1;
        }
      }
    }

    ul {
      padding-left: 30px;
      li {
        font-size: 14px;
        p {
          margin: 0;
          line-height: 1.5em;
        }
      }
    }
  }
}