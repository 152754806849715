@import '../../mixins.scss';

.register-container {
  width: 100%;
  background-color: white;
  font-family: $body-font;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  &__subtitle {
    font-family: $heading-font;
    font-weight: 400;
    text-align: center;
    font-size: 18px;
  }
  &__title {
    font-family: $heading-font;
    font-weight: 700;
    text-align: center;
    font-size: 36px;
    margin-bottom: 0;
  }
  &__links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 75%;
    align-self: center;
  }
  &__logo {
    box-sizing: border-box;
    height: 74px;
    &.ios-logo {
      padding: 12px;
    }
  }
  ol, uk {
    margin-top: 0;
    margin-bottom: 0;
  }
  &__video {
    padding-top: 1em;
    margin: auto;
  }
}