@import '../../mixins.scss';

.rating-container {
  background-color: white;
  width: 100%;
  font-family: $body-font;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  &__subtitle {
    font-family: $heading-font;
    font-weight: 400;
    text-align: center;
    font-size: 18px;
  }
  &__title {
    font-family: $heading-font;
    font-weight: 700;
    text-align: center;
    font-size: 36px;
    margin-bottom: 0;
  }
  &__star-box {
    display: flex;
    align-self: center;
  }
  &__rating {
    align-self: center;
    font-weight: 700;
    font-size: 24px;
    @media only screen and (min-width: 450px) {
      font-size: 36px;
    }
    color: #555;
  }
  &__message {
    text-align: center;
  }
  &__submit {
    font-family: $body-font;
    width: max-content;
    align-self: center;
    padding: 6px 30px 8px 30px;
    color: white;
    background-color: $blue;
    border-radius: 5px;
    font-weight: 700;
    font-size: 24px;
    border: none;
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: $orange;
    }
    &.disabled {
      background-color: #aaa;
    }

  }
}

.single-star {
  width: 50px;
  height: 50px;
  @media only screen and (min-width: 300px) {
    width: 60px;
    height: 60px;
  }
  @media only screen and (min-width: 450px) {
    width: 80px;
    height: 80px;
  }
}
