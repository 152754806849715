@import '../../mixins.scss';


.app-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  @media only screen and (min-width: $break-mobile) {
    padding-bottom: 25px;
  }
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    @include container-width;
    height: auto;
    @media only screen and (min-width: $break-mobile) {
      height: 80px;
    }
  }
  &__logo img {
    height: 46px;
    @media only screen and (min-width: $break-mobile) {
      height: 60px;
    }
    display: block;
    padding: 2px 0;
  }

  &__desktop-phone-cta {
    display: none;
    @media only screen and (min-width: $break-mobile) {
      display: flex;
    }
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    svg {
      fill:#0357a5;
      height: 30px;
      padding-right: 10px;
    }
    & p.phone-now {
      font-family: $heading-font;

      font-size: 24px;
      margin: 0;

      font-weight: 700;
      background: -moz-linear-gradient(0deg, #00b994 0%, #0077e6 100%);
      background: -webkit-linear-gradient(0deg, #00b994 0%, #0077e6 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;

      & span {
        font-weight: 400;
        color: #333;
        -webkit-text-fill-color: initial;
        @media only screen and (max-width: 380px) {
          display: none;
        }
      }

    }
  }
  &__mobile-phone-cta {
    @media only screen and (min-width: $break-mobile) {
      display: none;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    svg {
      &.phone-icon {
        display: none;
        @media only screen and (min-width: 350px) {
          display: block;
        }
      }

      fill:#0357a5;
      height: 30px;
      padding-right: 10px;
    }

    & .phone-now {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;


      outline: none;
      border: none;

      padding: 10px 45px 10px 35px;
      border-radius: 12px;
      background-image: -webkit-linear-gradient(0deg, #00b994 0%, #0077e6 100%);


      h2 {
        line-height: 1em;
        margin: 0;
        color: white;
        // text-transform: uppercase;
        font-family: $body-font;
        font-weight: 400;
        font-size: 22px;
      }
      svg {
        position: absolute;
        bottom: 5px;
        right: 5px;
        fill: white;
        height: 27px;
        padding: 0;
      }
    }
  }

  &__menu {
    display: none;
    @media only screen and (min-width: $break-mobile) {
      display: flex;
    }
    width: 100%;
    @include container-width;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    background-image: -moz-linear-gradient(0deg, #00b994 0%, #0077e6 100%);
    background-image: -webkit-linear-gradient(0deg, #00b994 0%, #0077e6 100%);
    align-self: center;
    position: absolute;
    bottom: -20px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

  }
  &__menu-left, &__menu-right {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    padding-left: 0;;
    li {
      padding-left: 20px;
      &:first-child {
        padding-left: 0;
      }
      a {
      color: white;
      text-decoration: none;
      text-transform: uppercase;
      font-family: $body-font;
      font-weight: 400;
      font-size: 14px;
      }
    }
  }
}
