$highlight: #00b994; 
$blue: #0077e6;
$orange: orange;

$body-font: 'Open Sans', sans-serif;
$heading-font: 'Open Sans', sans-serif;
$phone-font: 'Lato', sans-serif;

$break-mobile: 480px;
$break-tablet: 600px;
$break-wide: 800px;
$break-landscape: 1000px;
$break-desktop: 1200px;

$width-wide: 650px;
$width-landscape: 910px;
$width-desktop: 1090px;

@mixin container-padding {
  padding-left: 20px;
  padding-right: 20px;
  @media only screen and (min-width: 600px) {
    padding-left: 0;
    padding-right: 0;
  }
}

@mixin banner-width {
    width: 100%;
    @media only screen and (min-width: 600px) {
        width: 510px;
    }
    @media only screen and (min-width: 800px) {
        width: 690px;
    }
    @media only screen and (min-width: 1200px) {
        width: 825px;
    }
}

@mixin container-width {
    width: 100%;
    @media only screen and (min-width: 600px) {
        width: 480px;
    }
    @media only screen and (min-width: 800px) {
        width: 600px;
    }
    @media only screen and (min-width: 1000px) {
        width: 700px;
    }
    @media only screen and (min-width: 1200px) {
        width: 900px;
    }
}