@import '../../mixins.scss';

@mixin font-size($mobile, $desktop) {
  font-size: $mobile;
  @media only screen and (min-width: 550px) {
    font-size: $desktop;
  }
}

.scroll-arrow {
  height: 20px;
  stroke: #00b994;
  fill: none;
  stroke-width:20px;
  @media only screen and (min-width: 550px) {
    display: none;
  }
  padding-bottom: 2px;

}

.banner-content {
    box-sizing: border-box;

    // margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
    @media only screen and (min-width: 550px) {
      padding-top: 0;
      height: auto;
    }
    background: rgb(18, 18, 31);
    // background: rgba(255,0,0,0.8);

    align-items: center;

    &__top-items {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    &__bottom-items {
      width: 100%;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }



    & p {
      margin: 0;
      @media only screen and (min-width: 550px) {
        margin: 0.5em;
      }
    }

    &__image-block {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 0;
      padding-bottom: 68%;
      img {
        width: 100%;
        display: block;
      }
      .image-mask {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
        &.high {
          background-color: rgba(0,0,0,0.6);
        }
      }
      .title {
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-content: center;
        h1, a.title-phone {
          margin: 0;
          font-family: $body-font;
          font-weight: 400;
          @include font-size(6vw, 30px);
          // font-size: 24px;
          text-align: center;
          color: white;
          // &:first-child {
          //   color: rgb(255, 208, 0);
          // }
          span {
            font-weight: 400;
          }
          &.main-title {
            @include font-size(11vw, 60px);
            // font-size: 44px;
            line-height: 1em;
            font-family: $body-font;
            letter-spacing: 0px;
            font-weight: 700;
            color: #ffad33;
          }
          &.title-phone {
            display: block;
            width: max-content;
            align-self: center;
            text-decoration: none;
            margin-bottom: 5px;
            padding: 15px 20px 8px 20px;
            border-radius: 20px;
            background-image: -webkit-linear-gradient(0deg, #00b994 0%, #0077e6 100%);
            p {
              margin: 0;
              line-height: 0.8em;
              @include font-size(11vw, 48px);
              font-family: $phone-font;
              font-weight: 700;
              &:last-child {
                padding-top: 8px;
                @include font-size(4vw, 20px);
                font-family: $body-font;
                font-weight: 400;
              }
              span {
                color: #ffad33;
              }
            }
          }
        }
      }
    }

    &__content {
        font-family: $body-font;
        font-weight: 400;
        text-align: center;
        @include font-size(5vw, 24px);
        color: white;
        & a {
            color: $highlight;
            text-decoration: none;
        }
        &.terms {
          @include font-size(2.5vw, 12px);
        }
        &.locations {
          padding-top: 0.5em;
          padding-bottom: 0.5em;
          @include font-size(4.5vw, 24px);
          @media only screen and (min-width: 550px) {
            letter-spacing: 1px;
          }
        }

    }

    // TODO: Cross-browser implementation
    &__phone {
        margin-top: 15px;
        margin-bottom: 30px;
        font-family: $heading-font;
        font-weight: 700;
        font-size: 36px;
        @media only screen and (min-width: 400px) {
          font-size: 48px;
        }
        background: -moz-linear-gradient(0deg, #00b994 0%, #0077e6 100%);
        background: -webkit-linear-gradient(0deg, #00b994 0%, #0077e6 100%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &__button {
        margin-bottom: 10px;
        font-family: $body-font;
        font-weight: 400;
        font-size: 20px;
        outline: none;
        text-decoration: none;
        text-transform: uppercase;
        padding: 15px 30px 15px 30px;
        background-image: -webkit-linear-gradient(0deg, #00b994 0%, #0077e6 100%);
        color: white;
        &:hover {
            outline: 1px solid white;
            background-image: none;
        }
    }

    &__logos {
        width: 90%;
    }

}

#above-fold .optional-medium, #above-fold .optional-long {
  display: none;
}

@media only screen and (max-width: 479px) {
  #above-fold.medium .optional-medium {
    display: block;
  }
}

@media only screen and (max-width: 479px) {
  #above-fold.long .optional-long {
    display: block;
  }
}

.home-register {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 1em;
  text-decoration: none;
  h2 {
    font-family: $heading-font;
    font-weight: 400;
    font-size: 1.5em;
    margin: 0;
    color: #333;
  }
  p {
    margin-top: 0.5em;
    font-family: $body-font;
    font-weight: 400;
    font-size: 1em;
    color: #333;
  }
  &__button {
    width: 80%;
    align-self: center;
    background-color: #0077e6;
    border: none;
    color: white;
    font-family: $heading-font;
    font-weight: 400;
    font-size: 5vw;
    padding: 0.5em 0;
    background: -moz-linear-gradient(0deg, #00b994 0%, #0077e6 100%);
    background: -webkit-linear-gradient(0deg, #00b994 0%, #0077e6 100%);
    border-radius: 10px;
    @media only screen and (min-width: $break-mobile) {
      font-size: 1.6em;
      width: auto;
      padding: 0.5em 2em;
    }
  }
}