@import './mixins.scss';


.appframe {
  width: 100%;
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-footer {
  width: 100%;
  &__body {
    margin: auto;
    max-width: 600px;
    @media only screen and (min-width: 1024px) {
      max-width: 800px;
    }
    height: 200px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    background-color:cornflowerblue
  }
}

.main-content {
width: 100%;
margin-top: 50px;
@media only screen and (min-width: $break-mobile) {
  margin-top: 100px;
}
flex: 1 0 auto;
  background-color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  &__left, &__right {
    // display: none;
    // @media only screen and (min-width: 750px) {
    //   display: block;
    // }
    flex: 1 1 auto;
    // height: 300px;

  }

  &__left, &__right {
    background-image: url(./img/left.jpg), url(./img/right.jpg);
    background-size: auto 100vh, auto 100vh;
    background-repeat: no-repeat, repeat-y;
  }

  &__right {
    background-image: url(./img/right.jpg), url(./img/left.jpg);

  }

  &__body {

    box-sizing: border-box;
    @include container-width;
    max-width: 750px;
    @media only screen and (min-width: $break-mobile) {
      padding-top: 25px;
    }
  }
  &__above-fold {
    height: auto;
    &.full {
      // height: calc(100vh - 43px); // Will get adjusted by javascript
      height: auto; // Will get adjusted by javascript
      @media only screen and (min-width: $break-mobile) {
        height: auto;
      }
    }

  }
  &__below-fold {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
  }





}



















body {
  background-color: #111;
}

.banner-outer {
    // background: cyan;

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    overflow: hidden;

}

.banner-outer-wrapper {
  @include container-padding;
  @include container-width;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.home {
    height: 100vh;
    @media only screen and (min-width: 550px) {
      height: auto;
    }
  }

  @media only screen and (min-width: 550px) {
    padding-top: 50px;
    height: auto;
  }
}

#cookie-warning {
  font-family: $body-font;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 550px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  justify-content: space-between;
  padding-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  p {
    font-weight: 400;
    font-size: 12px;
    background-color: transparent;
    padding-left: 20px;
    padding-right: 20px;
    color: #eee;
    text-align: center;
    margin-top: 3px;
    margin-bottom: 3px;
    a {
      text-decoration: none;
      color: $highlight;
      &:hover {
        color: $orange;
      }
    }
  }
  button {
    font-weight: 400;
    font-size: 14px;
    width: max-content;
    align-self: center;
    margin-right: 20px;
    padding: 2px 20px 3px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    color: white;
    background-color: $blue;
    &:hover {
      background-color: $orange;
    }
    margin-top: 4px;
    margin-bottom: 4px;
    @media only screen and (min-width: 550px) {
      margin-top: 0;
      margin-bottom: 0;
    }

  }
}

