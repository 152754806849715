@import '../../mixins.scss';


.terms-container {
  background-color: white;
  margin-bottom: 50px;
  font-family: $body-font;

  h2 {
    font-family: $heading-font;
    font-weight: 700;
    font-size: 24px;
    // text-transform: uppercase;
  }
  h4 {
    font-family: $heading-font;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  h5 {
    font-family: $heading-font;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  ul {
    li {
      // color:red;
    }
  }

  &__address {
    padding-left: 50px;
    padding-top: 10px;
  }

  &__in-short {
    font-style: italic;
    span {
      font-weight: 700;
    }
  }

  &__payment-plan {
    padding-bottom: 10px;
    span {
      padding-left: 20px;
    }
  }
}